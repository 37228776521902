import React, { Component } from 'react'
import { Link } from "gatsby"
import Header from "../components/Header"
import ProjectHeader from "../components/ProjectHeader"
import Footer from "../components/Footer"
import '../css/project.css'
import Fade from 'react-reveal/Fade';
import SmallPic from '../components/SmallPic'

class Facebook extends Component {
    render() {
      return (
        <div>
        <Header />
        <Fade>
        <div className="ProjectContainer">
        <ProjectHeader
            title="Adobe"
            description="Product Design Internship"
            timeline="Summer 2018"
            image={require('../images/adobe.png')}
        />
        <h6>Background</h6>
        <h2>
            <a href="https://lightroom.adobe.com">Adobe Lightroom</a> is the go-to photo editing application in the Creative Cloud Suite used by both professional and novice photographers.
        </h2>
        <p>
            Lightroom CC is a photo-editing and organizational tool used by professional photographers. It, along with its original counterpart, Lightroom Classic, is considered the industry standard for editing.
        </p>
        <p>
            During my time at Adobe, my team was working on incredibly exploratory projects, such as launching a redesign of the app and imagining what an online photography community could look like.        
        </p>   
            <h6>Projects</h6>
            <h2>
            My projects focused on how Lightroom could leverage machine learning and AI technology to ehance the overall experience of the new Lightroom CC.
            </h2>
            <p>
            Throughout the summer, I created and delivered prototypes using Adobe’s existing design system, <a href="https://spectrum.adobe.com">Spectrum</a>, that I had the opportunity to user test and iterate on. 
            </p>
            <SmallPic
                image={require('../images/interns.png')}
                caption="Design interns post-final presentation!"
            />  
            <h2 className="ComingSoon">Please reach out if you’d like to learn more about my work.</h2>
            <Link className="BackHome" to="/">Back Home</Link>
        </div>
        </Fade>
        <Footer />
  </div>
      )
    }
  }
  
  export default Facebook;