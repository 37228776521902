import React, { Component } from 'react'
import '../css/SmallPic.css'

class SmallPic extends Component {
    render() {
      return (
        <div className="SmallPic">
            <img src={this.props.image}/>
            <p className="description">{this.props.caption}</p>
        </div>   
      )
    }
  }
  
  export default SmallPic;


  