import React, { Component } from 'react'
import Link from 'gatsby-link'
import '../css/ProjectHeader.css'

const ProjectHeader = props => (
    <div>
        <div className="ProjectHeader">
            <div className="ProjectHeaderGroup">
            <div className="ProjectDetails">
                <h1 className="title">{props.title}</h1>
                <h3 className="project-description">{props.description}</h3>
                <h3 className="timeline">{props.timeline}</h3>
            </div>
            <div className="ProjectImage">
                <img src={props.image}/>
            </div>
            </div>
        </div>
    </div>
)

export default ProjectHeader

// class ProjectHeader extends Component {
//     render() {
//       return (
//         <div className="ProjectHeader">
//         <div className="ProjectHeaderGroup">
//         <div className="ProjectDetails">
//             <h1>{props.title}</h1>
//             <h3>{props.description}</h3>
//             <h3>{props.timeline}</h3>
//         </div>
//         <div className="ProjectImage">
//             <img src={props.image}/>
//         </div>
//         </div>
//     </div>
//       )
//     }
//   }
  
//   export default ProjectHeader